import logo from "./logo.svg";
import "./App.css";
import { FaInstagramSquare } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { useState } from "react";
import axios from "axios";

function App() {
  const mail = "contact@sanatcreatives.com";
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  const submithandler = async (event) => {
    event.preventDefault();

    try {
      await axios.post("/send-email", { email });
      setMessage("Email sent successfully!");
      setEmail("");
    } catch (error) {
      setMessage("Error sending email. Please try again later.");
      console.log(error.message);
    }
  };

  return (
    <div className="flex justify-center items-center w-screen h-screen bg-gradient-to-r from-cyan-500 to-blue-500 ...">
      <div className="flex flex-col items-center">
        <h1 className="text-7xl font-semibold text-slate-300 italic">
          Coming Soon
        </h1>
        <div className="text-3xl text-slate-300 flex justify-center flex-col items-center mt-6 mb-6">
          <p className="font-semibold">
            We are going to launch our website very soon
          </p>
          <span className="font-semibold">
            we are still
            <span className="font-bold italic text-4xl text-indigo-700">
              {" "}
              "Cooking our website"
            </span>
          </span>
        </div>
        <form className="flex gap-2" onSubmit={submithandler}>
          <input
            type="email"
            placeholder="email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-2 w-[300px] rounded-full outline-0"
          ></input>
          <a
            className="bg-zinc-900 text-slate-300 p-2 rounded-full px-8 hover:bg-zinc-700 hover:text-white"
            type="submit"
            href={`mailto:${mail}`}
          >
            Submit
          </a>
        </form>
        <div className="fixed bottom-0 mb-12 flex gap-5 justify-center items-center">
          <a
            href="https://discord.com/users/775299566080622602"
            target="_blank"
          >
            <FaDiscord size={30}></FaDiscord>
          </a>
          <a href="https://www.instagram.com/sanatcreatives" target="_blank">
            <FaInstagramSquare size={30}></FaInstagramSquare>
          </a>
          <a href="https://twitter.com/SanatWadyalkar" target="_blank">
            <FaTwitter size={30}></FaTwitter>
          </a>
          <a href={`mailto:${mail}`} target="_blank">
            <SiGmail size={30}></SiGmail>
          </a>
        </div>
        <div>{message}</div>
      </div>
    </div>
  );
}

export default App;
